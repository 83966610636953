import { Container, Image } from "react-bootstrap";
import { ListWinners } from "./components/ListWinners";
import { useEffect, useState } from "react";
import FooterComponent from "../../components/FooterComponent";
import VoucherService from "../../services/VoucherService";
import { IVoucher } from "../../interfaces/IVoucher";
import moment from "moment";

const WinnersPage = () => {
  const [list, setList] = useState<IVoucher[]>([]);

  useEffect(() => {
    const untilDate = moment().subtract(1, "day").format("YYYY-MM-DD");

    VoucherService.getListWinnersUntilDate(untilDate).then((res) => {
      let list = res.success ? (res.body as IVoucher[]) : [];

      list.sort((a, b) => a.gameOpportunity?.invoice?.client?.name?.localeCompare(b.gameOpportunity?.invoice?.client?.name || "") || 0)

      setList(list);
    });
  }, []);

  const showWinnersList = () => {
    let now = Number.parseInt(moment().format("YYYYMMDD"));

    return now > 20240901;
  };

  const getDateUntil = () => {
    return moment().subtract(1, "day").format("DD/MM");
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_winners page_ganhadores">
          <div className="hero-image hero-image-top ">
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
            <div className="img-wrapper">
              <Image src="./images/guirlanda-top.png" className="" />
            </div>
          </div>

          <div className="hero-inner">
            <Container className="position-relative">

              <header className="hero-header">
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className="img-fluid" />
                </div>
                <div className="rewards d-none d-md-flex  align-items-start overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
                </div>
              </header>

              {/* GANHADORES DO SORTEIO SEMANAL */}
              <div className="wrapper-ganhadores ganhadores-sorteio ">
                <h4 className="title title-ganhadores">
                  Ganhadores do Sorteio de R$50Mil
                </h4>
                <div className="next-draw-date">
                  Próximo sorteio: 14/12/2024
                </div>
                <ul className="winners-list text-start">
              
                  <li className="winner-item winner-atual">
                    <div className="number">4</div>
                    <div className="draw-date">07/12/2024</div>
                    <div className="winner-name">
                      NEILTON FERREIRA SILVA
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-82</div>
                    <div className="winner-number">6679241</div>
                    <div className="valor">R$50.000,00</div>
                  </li>

                  <li className="winner-item">
                    <div className="number">3</div>
                    <div className="draw-date">30/11/2024</div>
                    <div className="winner-name">
                      ALEX SANDRO OLIVEIRA DA SILVA
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-03</div>
                    <div className="winner-number">6832998</div>
                    <div className="valor">R$50.000,00</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">2</div>
                    <div className="draw-date">23/11/2024</div>
                    <div className="winner-name">
                      MARIANA MONTEIRO
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-28</div>
                    <div className="winner-number">4605975</div>
                    <div className="valor">R$50.000,00</div>
                  </li>

                  <li className="winner-item ">
                    <div className="number">1</div>
                    <div className="draw-date">16/11/2024</div>
                    <div className="winner-name">
                    LILIANE SOUZA DOS SANTOS	
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-60</div>
                    <div className="winner-number">0197379</div>
                    <div className="valor">R$50.000,00</div>
                  </li>

                  {/* GANHADOR atual 
                  <li className="winner-item">
                    <div className="number">2</div>
                    <div className="draw-date">19/09/2024</div>
                    <div className="winner-name">
                      VANESSA DA SILVA ENEDINA
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-77</div>
                    <div className="winner-number">2858976</div>
                    <div className="valor">R$30.000,00</div>
                  </li> 
                    */}

                </ul>
              </div>

              {/* GANHADORES DO SORTEIO INSTANTÂNEO */}
              <div className="wrapper-ganhadores ganhadores-vales">
                <h4 className="title title-ganhadores">
                  Ganhadores do Vale-compras de R$500,00
                </h4>

                {showWinnersList() ? (
                  <>
                    <h5 className="">
                      Ganhadores de 10/11 até {getDateUntil()}{" "}
                    </h5>
                    <ListWinners itens={list} />
                  </>
                ) : (
                  <h5 className="">LISTA DISPONÍVEL A PARTIR DE 10/11</h5>
                )}
              </div>

              <div className="rewards align-items-start d-flex d-md-none overflow-hidden">
                  <Image src="./images/bola-1.5m.png"  srcSet="./images/bola-1.5m@2x.png" className="ball ball-15" />                   
                  <Image src="./images/bola-1m.png" srcSet="./images/bola-1m@2x.png"  className="ball ball-1" />
                  <Image src="./images/bola-50mil.png" srcSet="./images/bola-50mil@2x.png"  className="ball  ball-50" />
              </div>
              
            </Container>
          </div>
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default WinnersPage;
